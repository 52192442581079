var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "설비 목록",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  selection: _vm.isMultiple ? "multiple" : "none",
                  data: _vm.grid.data,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  checkClickFlag: false,
                  hideBottom: true,
                  isExcelDown: false,
                  rowKey: "minEquipmentCheckId",
                  checkDisableColumn: "disable",
                },
                on: { rowClick: _vm.rowClick },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "complete" && _vm.isMultiple
                          ? [
                              _c("q-btn", {
                                staticClass: "glossy",
                                attrs: {
                                  round: "",
                                  size: "sm",
                                  editable: _vm.editable,
                                  disable: props.row.disable,
                                  color: "blue-6",
                                  icon: "save",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.resultComplete3(props.row)
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled && _vm.isMultiple
                          ? _c(
                              "q-btn",
                              {
                                staticClass: "custom-btn",
                                attrs: {
                                  icon: "help",
                                  color: "deep-purple-6",
                                  "text-color": "white",
                                  align: "center",
                                  round: "",
                                },
                              },
                              [
                                _c(
                                  "q-tooltip",
                                  {
                                    attrs: {
                                      anchor: "bottom left",
                                      self: "top left",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tooltipCustomTop" },
                                      [_vm._v(" 버튼설명 ")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tooltipCustom" },
                                      [
                                        _vm._v(
                                          " o 선택완료 : 체크박스를 선택하여 점검완료 하는 버튼입니다."
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " o 일괄저장 : 설비목록과 설비별 점검항목, 개선관리를 일괄적으로 저장하는 버튼입니다."
                                        ),
                                        _c("br"),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.editable && !_vm.disabled && _vm.isMultiple
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "선택완료",
                                icon: "save",
                                color: "blue",
                              },
                              on: { btnClicked: _vm.resultComplete },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled && !_vm.isMultiple
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "완료",
                                icon: "save",
                                color: "blue",
                              },
                              on: { btnClicked: _vm.resultComplete2 },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.updateUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.grid.data,
                                mappingType: "PUT",
                                label: _vm.isMultiple ? "일괄저장" : "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveInspection,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-tab", {
            attrs: {
              type: "tabcard",
              tabItems: _vm.tabItems,
              inlineLabel: true,
              align: "left",
            },
            on: {
              "update:tabItems": function ($event) {
                _vm.tabItems = $event
              },
              "update:tab-items": function ($event) {
                _vm.tabItems = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (tab) {
                  return [
                    _c(tab.component, {
                      tag: "component",
                      attrs: {
                        result: _vm.result,
                        count: _vm.count,
                        isMultiple: _vm.isMultiple,
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.tab,
              callback: function ($$v) {
                _vm.tab = $$v
              },
              expression: "tab",
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }